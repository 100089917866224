var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"login-box"},[_c('img',{attrs:{"src":_vm.logo}}),_vm._m(0),_c('a-form',{ref:"formLogin",staticClass:"user-layout-login",attrs:{"id":"formLogin","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'username',
						{
							rules: [
								{ required: true, message: '请输入帐户' }
							],
							validateTrigger: 'blur'
						}
					]),expression:"[\n\t\t\t\t\t\t'username',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{ required: true, message: '请输入帐户' }\n\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\tvalidateTrigger: 'blur'\n\t\t\t\t\t\t}\n\t\t\t\t\t]"}],attrs:{"size":"large","type":"text","placeholder":"帐户"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',{staticStyle:{"margin-bottom":"32px"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'password',
						{
							rules: [
								{
									required: true,
									message: '请输入密码, 最少6位',
									min: 6
								}
							],
							validateTrigger: 'blur'
						}
					]),expression:"[\n\t\t\t\t\t\t'password',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\tmessage: '请输入密码, 最少6位',\n\t\t\t\t\t\t\t\t\tmin: 6\n\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\tvalidateTrigger: 'blur'\n\t\t\t\t\t\t}\n\t\t\t\t\t]"}],attrs:{"size":"large","placeholder":"密码"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',{staticStyle:{"margin-top":"24px"}},[_c('a-button',{staticClass:"login-button",attrs:{"size":"large","type":"primary","htmlType":"submit","loading":_vm.loginLoading}},[_vm._v("登录")])],1)],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title-box"},[_c('div',{staticStyle:{"font-size":"20px"}},[_vm._v("轻舟能科官网后台")])])}]

export { render, staticRenderFns }